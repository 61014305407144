import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import IPv6ForwardingVideo from 'components/Frequently_Asked_Question/WQHD_IPv6_Portforwarding/IPv6ForwardingVideo';
export const _frontmatter = {
  "title": "Instructions for IPv6 port activation in an AVM Fritzbox",
  "path": "/Frequently_Asked_Question/WQHD_IPv6_Portforwarding/",
  "dateChanged": "2024-05-17",
  "author": "Mike Polinowski",
  "excerpt": "In this guide, we will show you how to perform IPv6 port activation on your AVM Fritzbox.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Instructions for IPv6 port activation in an AVM Fritzbox' dateChanged='2024-05-17' author='Mike Polinowski' tag='INSTAR IP Camera' description='In this guide, we will show you how to perform IPv6 port activation on your AVM Fritzbox.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_IPv6_Portforwarding/' locationFR='/fr/Frequently_Asked_Question/WQHD_IPv6_Portforwarding/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "instructions-for-ipv6-port-activation-in-an-avm-fritzbox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instructions-for-ipv6-port-activation-in-an-avm-fritzbox",
        "aria-label": "instructions for ipv6 port activation in an avm fritzbox permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Instructions for IPv6 port activation in an AVM Fritzbox`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I use my camera in an IPv6 network and would like to access my camera externally via the DDNS address. What do I have to set in my AVM Fritzbox for this?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: In this guide, we will show you how to perform IPv6 port activation on your AVM Fritzbox. We will also explain how you can access your devices using your own INSTAR DDNS address and a direct global IPv6 address.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <IPv6ForwardingVideo mdxType="IPv6ForwardingVideo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "step-1-open-the-fritzbox-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-1-open-the-fritzbox-user-interface",
        "aria-label": "step 1 open the fritzbox user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1: Open the Fritzbox user interface`}</h2>
    <ol>
      <li parentName="ol">{`open your web browser.`}</li>
      <li parentName="ol">{`enter `}<strong parentName="li"><a parentName="strong" {...{
            "href": "http://fritz.box"
          }}>{`http://fritz.box`}</a></strong>{` in the address bar and press the Enter key.`}</li>
      <li parentName="ol">{`log in with your Fritzbox user name and password.`}</li>
    </ol>
    <h2 {...{
      "id": "step-2-set-up-ipv6-port-activation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-2-set-up-ipv6-port-activation",
        "aria-label": "step 2 set up ipv6 port activation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2: Set up IPv6 port activation`}</h2>
    <ol>
      <li parentName="ol">{`click on `}<strong parentName="li">{`"Internet"`}</strong>{` in the menu and then on `}<strong parentName="li">{`"Shares”`}</strong>{`.`}</li>
      <li parentName="ol">{`switch to the `}<strong parentName="li">{`"Port Forwarding"`}</strong>{` tab.`}</li>
      <li parentName="ol">{`click on `}<strong parentName="li">{`"Add device for shares ”`}</strong>{`.`}</li>
      <li parentName="ol">{`select the device for which you want to set up the port share and click on `}<strong parentName="li">{`"New share ”`}</strong>{`.`}</li>
      <li parentName="ol">{`select `}<strong parentName="li">{`“Port Forwarding"`}</strong>{` and then `}<strong parentName="li">{`"IPv6”`}</strong>{`.`}</li>
      <li parentName="ol">{`enter the following information:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Name`}</strong>{`: Any name for the share, e.g. “INSTAR Camera”.`}</li>
          <li parentName="ul">{`Protocol**: Select the required protocol (TCP, UDP or both).`}</li>
          <li parentName="ul"><strong parentName="li">{`From port`}</strong>{`: `}<strong parentName="li">{`In case of an IPv4 address`}</strong>{` you can forward an external port to an internal port here. But `}<strong parentName="li">{`for IPv6 addresses`}</strong>{` the you only open a port to your camera. So this input field allows you to define a starting port of a port range you want to open!`}</li>
          <li parentName="ul"><strong parentName="li">{`To port`}</strong>{`: This is the last port of the range you want to open to your camera. If you only want to open a single port you need to set both `}<strong parentName="li">{`Form port`}</strong>{` and `}<strong parentName="li">{`To port`}</strong>{` to the same value - e.g. `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`443`}</code>{`.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`click on `}<strong parentName="li">{`"OK"`}</strong>{` and then on `}<strong parentName="li">{`"Apply”`}</strong>{` to save the settings.`}</li>
    </ol>
    <h2 {...{
      "id": "access-via-instars-own-ddns-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#access-via-instars-own-ddns-address",
        "aria-label": "access via instars own ddns address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access via INSTAR's own DDNS address`}</h2>
    <p>{`INSTAR offers its own DDNS service, which you can use to access your devices from anywhere.`}</p>
    <ol>
      <li parentName="ol">{`open your web browser.`}</li>
      <li parentName="ol">{`enter the following in the address bar: `}<strong parentName="li"><a parentName="strong" {...{
            "href": "http://ddns-ihrer-kamera.ddns3-instar.de:Portnummer"
          }}>{`http://ddns-ihrer-kamera.ddns3-instar.de:Portnummer`}</a></strong>
        <ul parentName="li">
          <li parentName="ul">{`Beispiel: `}<strong parentName="li"><a parentName="strong" {...{
                "href": "http://abcd12.ddns3-instar.de:8081"
              }}>{`http://abcd12.ddns3-instar.de:8081`}</a></strong></li>
        </ul>
      </li>
      <li parentName="ol">{`Press the enter key.`}</li>
    </ol>
    <h2 {...{
      "id": "access-with-direct-global-ipv6-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#access-with-direct-global-ipv6-address",
        "aria-label": "access with direct global ipv6 address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access with direct global IPv6 address`}</h2>
    <p>{`You can also access your devices directly via the global IPv6 address.`}</p>
    <ol>
      <li parentName="ol">{`open your web browser.`}</li>
      <li parentName="ol">{`enter the IPv6 address of your device in the address bar. Use square brackets to enter the address:`}
        <ul parentName="li">
          <li parentName="ul">{`Beispiel: `}<strong parentName="li">{`http://`}{`[2001:0db8:85a3:0000:0000:8a2e:0370:7334]`}{`:Portnummer`}</strong></li>
          <li parentName="ul">{`Beispiel: `}<strong parentName="li">{`http://`}{`[2001:0db8:85a3:0000:0000:8a2e:0370:7334]`}{`:8081`}</strong></li>
        </ul>
      </li>
      <li parentName="ol">{`Press the enter key.`}</li>
    </ol>
    <h2 {...{
      "id": "summary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#summary",
        "aria-label": "summary permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Summary`}</h2>
    <p>{`With this guide you have learned how to set up an IPv6 port activation in your AVM Fritzbox and how to access your devices using an INSTAR own DDNS address or a direct global IPv6 address. If you have any questions or need support, please contact our customer service.`}</p>
    <hr></hr>
    <p>{`This guide is designed to help you manage your network devices securely and efficiently. We hope you find the steps simple and easy to follow. Good luck!`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      